<template>
  <div class="container">
    <div class="row text-center my-5" v-if="busy">
      <div class="col">
        <i class="fad fa-spinner fa-5x fa-spin text-primary"></i>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col">
        <div class="card border-0 shadow-none">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <div class="row mb-2">
                  <div class="col">
                    <h5 class="pt-1">
                      Shared Files
                    </h5>
                  </div>
                  <div class="col col-auto text-right">
                    <button class="btn btn-primary btn-sm"
                            v-if="!showUpload"
                            @click="showUpload=true">
                      <i class="fad fa-upload mr-1"></i>
                      Upload File
                    </button>
                    <button class="btn btn-outline-primary btn-sm"
                            v-if="showUpload"
                            @click="showUpload=false">
                      <i class="fad fa-arrow-left mr-1"></i>
                      Go Back
                    </button>
                  </div>
                  <div class="col-auto ml-auto text-right" v-if="folderOpen">
                    <span class="mr-2">Current folder: {{ folderOpen.name }}</span>
                    <button
                      class="btn btn-outline-primary"
                      @click="upLevel()"
                    >
                      <i class="fad fa-level-up-alt"></i>
                    </button>
                  </div>
                </div>
                <div class="row mb-3" v-if="showUpload">
                  <div class="col">
                    <vue-dropzone
                            class="mb-3"
                            ref="clientDropZone"
                            id="dropzone"
                            :options="dropzoneOptions"
                            @vdropzone-queue-complete="uploadComplete"
                    ></vue-dropzone>
                  </div>
                </div>
                <div class="card shadow-none border-0 bg-light" v-if="!showUpload">
                  <div class="card-body">
                    <!--  -->
                    <div class="row" v-if="files.length > 0">
                      <div class="col-md-3 mb-2 text-center" v-for="f in currentFolders()" :key="f.id">
                        <div class="card border-0 shadow-sm cursor-pointer h-100" @click="folderOpen = f">
                          <div class="card-body" >
                            <i class="fad fa-4x text-primary mb-3" :class="'fa-folder'"></i>
                            <h5 class="mb-0">{{ f.name }}</h5>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 mb-2 text-center" v-for="f in currentFiles()" :key="f.id">
                        <div class="card border-0 shadow-sm cursor-pointer h-100" @click="goTo(f)">
                          <div class="card-body" >
                            <span v-if="!f.viewed_by_client"
                                  class="badge badge-danger badge-top-right">New</span>
                            <i class="fad fa-4x text-primary mb-3" :class="'fa-' + f.file.type"></i>
                            <h5 class="mb-0">{{ f.file.title }}</h5>
                            <p v-if="f.viewed_by_client" class="">
                              <small>Last viewed - {{ f.updated_at | formatDate }}</small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--  -->
                    <div class="row text-center" v-if="files.length == 0">
                      <div class="col">
                        <i class="fad fa-folders fa-5x text-primary mb-4"></i>
                        <p>No files have been shared by your practitioner yet</p>
                      </div>
                    </div>
                    <!--  -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  props: [],
  data() {
    return {
      files: [],
      folders: [],
      folderOpen: null,
      busy: true,
      showUpload: false,
      dropzoneOptions: {
        url: process.env.VUE_APP_API_URL + "/client/files/upload",
        thumbnailWidth: 300,
        headers: {
          Authorization: "Bearer " + this.$store.token.access_token,
        },
        dictDefaultMessage:
                "<i class='fad fa-upload fa-4x text-primary'></i><br><br>Click here to select a file from your computer or drag n' drop a file to begin uploading"
      }
    };
  },
  computed: {
    client() {
      return this.$store.user
    }
  },
  methods: {
    isFolderEmpty(folderId) {
      return !(this.files.find((file) => file.file.folder_id === folderId));
    },
    currentFolders() {
      const vm = this;
      if (this.folderOpen) {
        return this.folders.filter(function (el) {
          return el.folder_id === vm.folderOpen.id;
        });
      } 
      else 
      {
        return this.folders.filter(function (el) {
          return el.folder_id == null;
        });
      }
    },
    currentFiles() {
      if (this.folderOpen) {
        const vm = this;
        return this.files.filter(function (file) {
            return file.file.folder_id === vm.folderOpen.id;
        });
      }
      else {
        return this.files.filter(function (file) {
            return !file.file.folder_id;
        });
      }
    },
    goTo(file) {
      this.markAsViewed(file.id);
      window.open(file.file.url, "_blank");
    },
    markAsViewed(id) {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/client/files/mark-file-as-viewed",
            {
              'file_id': id
            })
        .then(({ data }) => {
          this.fetchClientFiles();
        });
    },
    fetchClientFiles() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/client/files/fetch-client-files")
        .then(({ data }) => {
          this.files = data;
          this.busy = false;
        });
    },
    fetchFolders() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/client/files/fetch-client-folders")
        .then(({ data }) => {
          this.folders = data;
        });
    },
    uploadComplete() {
      this.fetchFolders();
      this.fetchClientFiles();
      this.showUpload = false;
    },
    upLevel() {
      this.$nextTick(() => {
        if (this.folderOpen.folder_id) {
          this.folderOpen = this.getFolderById(this.folderOpen.folder_id);
        } else {
          this.folderOpen = null; 
        }
      });
    },
    getFolderById(folderId) {
      return this.folders.find((f) => f.id === folderId);
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("L");
    }
  },
  mounted() {
    this.fetchFolders();
    this.fetchClientFiles();
  },
  components: {
    vueDropzone: vue2Dropzone
  }
};
</script>

<style>
  .dz-preview{
    position: unset !important;
    display: block !important;
  }
</style>
